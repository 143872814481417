<template>
	<div>
		<select class="devices__select devices__select_service filter-item"
				@change="selectService($event)"
				:class="{'devices__select--active': title.name !== service.name}">
			<option
				:selected="option.selected"
				:value="option.index"
				v-for="option of service.values">
				{{ option.name }}
			</option>
		</select>
		<p class="devices__paragraph">{{ service.subText }}</p>
	</div>
</template>


<script>
	export default {
		props: ['service', 'selectedInfo'],
		data: () => ({
			title: '',
		}),
		methods: {
			selectService(event) {
				this.$emit('selectService', event.target.value);
			}
		},
		mounted() {
            this.service.values.forEach((service, index) => {
                if(index === +this.selectedInfo) {
                    service.selected = true;
                }
            })
			this.title = this.service.name;
			this.$emit('setStatusService', true);
		}
	};
</script>
