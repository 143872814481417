<template>
	<select class="devices__select devices__select_service"
			@change="updateArea($event)">
		<option
			:selected="option.selected"
			:value="option.index"
			v-for="option of devices">
			{{ option.name }}
		</option>
	</select>
</template>

<script>
	export default {
		name: 'selectDevice',
		props: ['devices', 'selectedInfo'],
		data: () => ({
			device: '',
			titleDevice: ''
		}),
		methods: {
			updateArea(event) {
				this.devices.forEach((service, index) => {
					service.selected = index === +event.target.value;
					if (service.selected) {
						this.titleDevice = service.name;
					}

				});
				this.$emit('setTitleDevice', this.titleDevice);
				this.$emit('updateArea', event.target.value);
			}
		},
		mounted() {
			this.devices.forEach((service, index) => {
				service.selected = index === +this.selectedInfo;
				if (service.selected) {
					this.titleDevice = service.name;
				}
			});
			this.$emit('setTitleDevice', this.titleDevice);
		}
	};
</script>

<style scoped>

</style>
