<template>
	<div>
		<input type="number"
			   class="devices__input devices__input--help"
			   :id="`device-price-${globalIndex}`"
			   @input="inputValue"
			   v-model="value">
		<label :for="`device-price-${globalIndex}`" class="devices__label">
			Стоимость 1го устройства
		</label>
		<span class="devices__help">₽</span>
	</div>
</template>

<script>
	export default {
		name: 'PriceDevice',
		props: ['globalIndex', 'valuePrice'],
		data: () => ({
			value: ''
		}),
		methods: {
			inputValue() {
				this.$emit('updateArea', this.value)
			}
		},
		mounted() {
			this.$emit('priceDeviceIsLoad');
			this.value = this.valuePrice;
		}
	};
</script>

<style scoped>

</style>
