<template>
	<div class="package__item package__item--program">
		<div class="package__trigger" @click="openProgram($event)">
			<input
				:checked="selectedInfo === indexProgram"
				@change="setSelectedProgram(indexProgram, program.price)"
				type="radio"
				class="circle-checkbox__input"
				:id="`available-program-${globalIndex}-${indexProgram}`">
			<label :for="`available-program-${globalIndex}-${indexProgram}`" class="circle-checkbox">
				<span class="circle-checkbox__group">
				  	<span class="circle-checkbox__icon"></span>
					<span class="circle-checkbox__price">{{ program.price.retail.toFixed(3) | price}} ₽</span>
					<!--<span class="circle-checkbox__discount">(15%)</span>-->
				</span>
			</label>
			<span class="package__dropdown"></span>
			<p class="devices__sub">
				<span class="devices__sub--price">{{ program.name }}</span>
			</p>
		</div>
		<div class="package__content">
			<p class="package__description">{{ program.subText }}</p>

			<!-- <div class="package__details" v-if="program.name === 'Карта Care+'"> -->
			<div class="package__details">

			<!-- 	<div class="devices__input-group">
					<input type="text" class="devices__input devices__input--help" id="care">
					<label for="care" class="devices__label">
						Ваша цена за шт.
					</label>
					<span class="devices__help">₽</span>
				</div> -->
				<div class="package__inner">
					<p class="package__group">
						<span>Себест-сть: </span>
						<span class="package__value">{{ program.price.base.toFixed(3) | price}} ₽</span>
					</p>
					<p class="package__group">
						<span>Выгода: </span>
						<span class="package__value">{{ program.price.profit.toFixed(3) | price}} ₽</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Program',
		props: ['indexProgram', 'program', 'globalIndex', 'selectedInfo'],
		methods: {
			setSelectedProgram(indexProgram, price) {
				this.$emit('setProgram', {indexProgram, price: price.base}, this.globalIndex);
			},
			openProgram(event) {
				if (event.target.closest('.package__item--program').classList.contains('package__item--active')) {
					event.target.closest('.package__item--program').classList.remove('package__item--active');
				} else {
					event.target.closest('.package__item--program').classList.add('package__item--active');
				}
			},
		}
	};
</script>
