<template>
	<select class="devices__select devices__select_service devices__select--active"
			@change="updateArea($event)">
		<option
			:selected="+option.index === +selectedInfo"
			:value="option.index"
			v-for="option of values[0].values">
			{{ option.name }}
		</option>
	</select>
</template>

<script>
	export default {
		name: 'ConveyorSelect',
		props: ['globalIndex', 'values', 'selectedInfo'],
		data: () => ({
			device: '',
		}),
		methods: {
			updateArea(event) {
				this.values[0].values.forEach((service, index) => {
					service.selected = index === +event.target.value;
				});
				this.$emit('updateArea', event.target.value);
			}
		},
		mounted() {
			this.$emit('setCountConveyorSelect');
			this.values[0].values.forEach((service, index) => {
				service.selected = index === +this.selectedInfo;
			});
		}
	};
</script>
