<template>
	<div class="wrap">
		<div class="bg-lines">
			<div class="line line1"></div>
			<div class="line line2"></div>
			<div class="line line3"></div>
			<div class="line line4"></div>
			<div class="line line5"></div>
			<div class="line line6"></div>
		</div>

		<div class="front-brands">
			<img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__brands">
			<img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__brands2">
		</div>

		<header class="header">

			<!--<img src="assets/img/index/circle-purple.png" alt="" class="circle_purple">-->

			<div class="container header__container">
				<a href="/" class="header__logo">
					<img src="../assets/img/dist/logo.png" alt="">
				</a>
				<nav class="header__nav">
					<a href="daas.html" class="header__nav-link">DAAS</a>
					<a href="trade-in.html" class="header__nav-link">Trade IN</a>
					<a href="http://isurance.ru/" class="header__nav-link" target="_blank">ServicePack</a>
					<a href="#" class="header__nav-link">Вакансии</a>
					<a href="https://crm.isurance.ru" class="header__nav-link"
					   target="_blank">Войти&nbsp;в&nbsp;лк</a>
					<a href="contact.html" class="header__nav-link">Контакты</a>
				</nav>
				<div class="header__phone-holder">
					<a href="tel:+74951503494" class="header__phone">+7 (495) 150-34-94</a>
				</div>
			</div>
		</header>

		<div class="home">
			<div class="home__wrapper">
				<a @click="$router.push({name: 'Home'})" class="home__back"></a>
				<div class="home__title">Калькулятор B2B услуг</div>
				<div class="home__group">
					<a href="" class="home__link" @click.prevent="createDublicate">Дублировать этот</a>
					<a class="home__btn" @click="createNewCalculate">Создать новый расчет</a>
				</div>
			</div>
			<div class="devices">
				<div class="devices__top">
					<div class="devices__group">
						<div class="devices__input-group">
							<input type="text"
								   v-model="calculationName"
								   @input="saveTitle"
								   class="devices__input devices__input--full" id="calculations-name"
								   placeholder="Название расчета">
							<label for="calculations-name" class="devices__label">Название расчета</label>
						</div>
						<a href="" class="devices__excel" download>
							<svg width="34" height="46" viewBox="0 0 34 46" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd"
									  d="M33.219 45H0.697266V1H21.7408L33.219 12.4783V45Z" fill="white" stroke="#58BB72"
									  stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M21.7401 1V12.4783H33.2184" stroke="#58BB72" stroke-linecap="round"
									  stroke-linejoin="round"/>
								<path fill-rule="evenodd" clip-rule="evenodd"
									  d="M6.4364 39.262H27.4799V16.3054H6.4364V39.262Z" stroke="#58BB72"
									  stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M6.4364 22.0446H27.4799" stroke="#58BB72" stroke-linecap="round"
									  stroke-linejoin="round"/>
								<path d="M6.4364 27.7836H27.4799" stroke="#58BB72" stroke-linecap="round"
									  stroke-linejoin="round"/>
								<path d="M6.4364 33.5228H27.4799" stroke="#58BB72" stroke-linecap="round"
									  stroke-linejoin="round"/>
								<path d="M14.087 16.3054V39.262" stroke="#58BB72" stroke-linecap="round"
									  stroke-linejoin="round"/>
							</svg>
						</a>
					</div>
					<div class="devices__sidebar devices__sidebar--accent">
						<p class="devices__sum">
							<span class="devices__sum--price">{{ totalPriceValue() | price }} ₽</span>
							<span class="devices__sum--text"> за весь период</span>
						</p>
						<p class="devices__sub">
							<span class="devices__sub--price">{{totalPriceValue() / 24 | price }} ₽</span>
							<span class="devices__sub--text"> в рассрочку на 24 мес.</span>
						</p>
					</div>
				</div>
				<div class="devices__wrapper">
					<div class="devices__item"
						 v-for="(group, globalIndex) of groups" :key="globalIndex">

						<div class="devices__trigger">
							<div class="devices__group">
								<div class="devices__more">
									<div class="devices__name" @click="openGroup($event, globalIndex)">
										{{group.name}} {{globalIndex + 1}}
									</div>
									<div class="devices__info"
										 v-if="group.deviceTitle.length && group.deviceTitle !== 'Выберете тип устройства'">
										{{group.deviceTitle}}:
										<span class="devices__info--value"
											  v-if="group.countDevice && group.countDevice.length > 0">
											{{group.countDevice}} шт.
										</span>

										<span class="devices__info--price"
											  v-if="group.priceDevice && group.priceDevice.length > 0">
											 по {{group.priceDevice}} ₽
										</span>
									</div>
								</div>

								<div class="devices__delete" @click="deleteGroup(globalIndex)">
									<svg width="17" height="18" viewBox="0 0 17 18" fill="none"
										 xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											  d="M15.9997 4.75041H0.996094L2.12136 2.49988H14.8744L15.9997 4.75041Z"
											  stroke="#8799AF" stroke-width="0.85" stroke-linecap="round"
											  stroke-linejoin="round"/>
										<path fill-rule="evenodd" clip-rule="evenodd"
											  d="M2.87048 17.5036H14.1232V4.75061H2.87048V17.5036Z" stroke="#8799AF"
											  stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
										<path fill-rule="evenodd" clip-rule="evenodd"
											  d="M10.3749 1H6.62396L5.87378 2.50036H11.125L10.3749 1Z" stroke="#8799AF"
											  stroke-width="0.85" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M7.37195 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85"
											  stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M9.62256 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85"
											  stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M5.12122 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85"
											  stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M11.8733 7.00122V15.2532" stroke="#8799AF" stroke-width="0.85"
											  stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
								</div>
							</div>

							<div
								class="devices__sidebar devices__sidebar--current"
							>
								<p class="devices__sum">
									<span v-if="group.selectedProgramPrice" class="devices__sum--price">
										{{ group.selectedProgramPrice.toFixed(3) | price}} ₽
									</span>
									<!--<span class="devices__sum--discount">(15%)</span>-->
								</p>
								<p class="devices__sub">
									<span v-if="group.selectedProgramPrice && group.programPackage[0].name.length"
										  class="devices__sub--price">
										{{ group.programPackage[0].name}}
									</span>
								</p>
							</div>
						</div>
						<div class="devices__content">
							<div class="devices__body">
								<div class="devices__inner">
									<select-device
										:devices="group.devices"
										:selectedInfo="group.device"
										@updateArea="updateArea($event, globalIndex, 'device')"
										@setTitleDevice="setTitleDevice($event, globalIndex, 'deviceTitle')"
									/>

									<div class="devices__input-group">
										<PriceDevice
											:valuePrice="group.priceDevice"
											:globalIndex="globalIndex"
											@priceDeviceIsLoad="setBuyoutLifecycle(globalIndex, group.priceDevice)"
											@updateArea="updateArea($event, globalIndex, 'priceDevice')"
										/>
									</div>

									<div class="devices__input-group devices__input-group--limit">
										<input-count-device
											:valuePrice="group.countDevice"
											:globalIndex="globalIndex"
											@updateArea="updateArea($event, globalIndex, 'countDevice')"
										/>
									</div>

								</div>
								<textarea-comments
									:valuePrice="group.comments"
									:globalIndex="globalIndex"
									@updateArea="updateArea($event, globalIndex, 'comments')"
								/>
								<div class="devices__block">
									<div class="devices__inner">
										<div class="devices__heading">Сервисная программа</div>
										<p class="devices__summary">
											<span class="devices__summary--price">0₽</span>
											&nbsp;
											<span class="devices__summary--discount">(0%)</span>
										</p>
									</div>
									<p class="devices__paragraph">
										В базовой комплектации входит год гарантийного обслуживания от производителя
									</p>

									<label v-for="(service, indexServices) of group.servicesSelect">
										<Select
											:service="service"
											:selectedInfo="group.filterString.charAt(indexServices)"
											@setStatusService="indexServices + 1 === group.servicesSelect.length ? setCountSymbolArea(globalIndex, indexServices + 1, 'filterString') : ''"
											@selectService="filterProgram(indexServices + 1, $event, true, globalIndex)"/>
									</label>

									<!--Ниже новый блок-->
									<div class="devices__small-heading">Где будут обрабатываться заявки</div>
									<p class="devices__paragraph">
										Call - Centr по номеру 8 800 дуступен всем Сервисным программам (СП)
									</p>

									<div class="devices__checkbox-group">
										<div
											v-for="(service, indexServicesMultiselect) of group.servicesMultiselect.values">
											<Orders
												class="custom-checkbox filter-item-multiselect"
												:service="service"
												:indexServicesMultiselect="`${indexServicesMultiselect}-${globalIndex}`"
												:selectedInfo="group.filterStringMultiselect.charAt(indexServicesMultiselect)"
												@setStatusService="indexServicesMultiselect + 1 === group.servicesMultiselect.values.length ? setCountSymbolArea(globalIndex, indexServicesMultiselect + 1, 'filterStringMultiselect') : ''"
												@selectOrder="filterProgramMultiselect(indexServicesMultiselect + 1, $event, globalIndex)"/>
										</div>
									</div>
								</div>

								<!--Ниже новый блок-->

								<div class="devices__block">
									<div class="devices__inner">
										<div class="devices__heading">Конвейер</div>
										<p class="devices__summary">
											<span class="devices__summary--price">{{+group.conveyorsBooleanExtraPrice / totalPriceValue() * 100 || 0  | price }} %</span>
										</p>
									</div>

									<p class="devices__paragraph">
										В базовой комплектации входит год гарантийного обслуживания + коллцентр
									</p>

									<conveyor-select
										:values="conveyorsSelect"
										:selectedInfo="group.conveyorsSelectString"
										@setCountConveyorSelect="setCountSymbolArea(globalIndex, 1, 'conveyorsSelectString')"
										@updateArea="updateArea($event, globalIndex, 'conveyorsSelectString')"
									></conveyor-select>

									<div class="devices__checkout filter-item-conveyor"
										 v-for="(conveyor, indexConveyors) of group.conveyorsBoolean">
										<Conveyor
											:conveyor="conveyor"
											:selectedInfo="group.filterStringConveyor.charAt(indexConveyors)"
											@isLoadInfo="indexConveyors + 1 === group.conveyorsBoolean.length ? setCountSymbolArea(globalIndex, indexConveyors + 1, 'filterStringConveyor') : ''"
											@selectConveyor="filterProgramCheckbox(indexConveyors + 1, $event, globalIndex, 'filter-item-conveyor', 'filterStringConveyor')"/>
									</div>

									<div class="devices__wrap">
										<div class="devices__input-group"
											 v-for="(conveyor, indexConveyorsHour) of group.conveyorsHour">
											<ConveyorTime
												:conveyor="conveyor"
												:index="`${indexConveyorsHour}-${globalIndex}`"
												@isLoadInfo="indexConveyorsHour + 1 === group.conveyorsHour.length ? setCountSymbolArea(globalIndex, indexConveyorsHour + 1, 'filterStringConveyorInput') : ''"
												@enterConveyor="enterValueArea($event, indexConveyorsHour, globalIndex, 'conveyorsHour')"/>
										</div>
									</div>
								</div>

								<div class="devices__block">
									<div class="devices__inner">
										<div class="devices__heading">Мониторинг использования устройств</div>
										<p class="devices__summary">
											<span class="devices__summary--price">{{+group.mdmBooleanExtraPrice / totalPriceValue() * 100 || 0  | price }} %</span>
										</p>
									</div>
									<p class="devices__paragraph">
										В базовой комплектации входит год гарантийного обслуживания + коллцентр
									</p>

									<Mdm
										class="devices__checkout filter-item-mdm"
										v-for="(item, indexMdm) of group.mdmCheckbox"
										:key="`mdmCheckbox-${indexMdm}-${globalIndex}`"
										:mdm="item"
										:selectedInfo="group.filterStringMdm.charAt(indexMdm)"
										@isLoadInfo="indexMdm + 1 === group.mdmCheckbox.length ? setCountSymbolArea(globalIndex, indexMdm + 1, 'filterStringMdm') : ''"
										@selectMdm="filterProgramCheckbox(indexMdm + 1, $event, globalIndex, 'filter-item-mdm', 'filterStringMdm')"
									/>

									<div class="devices__wrap" id="licenses-wrapper">
										<MdmInput
											v-for="(item, indexMdmInput) of group.mdmInput"
											:key="`mdmInput-${indexMdmInput}`"
											@isLoadInfo="indexMdmInput + 1 === group.mdmInput.length ? setCountSymbolArea(globalIndex, indexMdmInput + 1, 'filterStringMdmInput') : ''"
											:item="item"
											:index="indexMdmInput"
											@enterMdm="enterValueArea($event, indexMdmInput, globalIndex, 'mdmInput')"
										/>
									</div>
								</div>

								<div class="devices__block">
									<div class="devices__inner">
										<div class="devices__heading">Завершение жизненного цикла устройств</div>
										<p class="devices__summary">
											<span class="devices__summary--price">{{+group.lifeCycleBooleanExtraPrice / totalPriceValue() * 100 || 0  | price }} %</span>
										</p>
									</div>

									<p class="devices__paragraph">
										В базовой комплектации входит год гарантийного обслуживания + коллцентр
									</p>
									<Lifecycle
										class="devices__checkout filter-item-lifecycle"
										v-for="(device, indexLifeCycle) of group.lifeCycleDevice"
										:key="indexLifeCycle"
										:selectedInfo="group.filterStringLifecycle.charAt(indexLifeCycle)"
										:device="device"
										:priceDevice="group.priceDevice"
										:indexLifeCycle="indexLifeCycle"
										@isLoadInfo="indexLifeCycle + 1 === group.lifeCycleDevice.length ? setCountSymbolArea(globalIndex, indexLifeCycle + 1, 'filterStringLifecycle') : ''"
										@selectLifecycle="filterProgramCheckbox(indexLifeCycle + 1, $event, globalIndex, 'filter-item-lifecycle', 'filterStringLifecycle')"
									/>
								</div>
							</div>
							<div class="devices__sidebar devices__sidebar--select">
								<div class="devices__sticky">
									<div class="devices__heading">Доступные пакеты</div>
									<div class="package">
										<Program
											v-for="(program, indexProgram) of group.programPackage"
											:selectedInfo="group.selectedProgram"
											:key="indexProgram"
											:indexProgram="indexProgram"
											:globalIndex="globalIndex"
											:program="program"
											@setProgram="setProgram($event, globalIndex)"
										/>
									</div>
									<p class="devices__paragraph devices__paragraph--small">
										Итоговые цены указаны в руб., за
										все время и рассчитываются исходя из указанного типа, кол-ва, стоимости устр. за
										весь выбранный период обслуживания</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="devices__bottom">
					<div class="devices__btn" @click.prevent="addNewGroup">Добавить новую группу устройств</div>
				</div>
			</div>
		</div>

		<div class="bottom-logo">
			<img src="../assets/img/dist/logo-blue.png" alt="" class="bottom-logo__img">
		</div>
		<div class="front-conf">

			<img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__conf">
		</div>
		<footer class="footer">

			<img src="../assets/img/dist/index/circle-blue.png" alt="" class="circle__footer">

			<div class="container footer__container">
				<div class="footer__copyright">© 2018 Телекоминвест</div>
				<nav class="footer__nav">
					<a href="daas.html" class="footer__nav-link">DAAS</a>
					<a href="trade-in.html" class="footer__nav-link">Trade IN</a>
					<a href="http://isurance.ru/" class="footer__nav-link" target="_blank">ServicePack</a>
					<a href="#" class="footer__nav-link">Вакансии</a>
					<a href="https://crm.isurance.ru" class="footer__nav-link" target="_blank">Войти&nbsp;в&nbsp;лк</a>
					<a href="contact.html" class="footer__nav-link">Контакты</a>
				</nav>
				<div class="footer__phone">+7 (495) 150-34-94</div>
			</div>

			<Dropout v-if="isStatusSave"/>

		</footer>
	</div>
</template>


<script>
	import Select from '../components/Select';
	import ConveyorTime from '../components/ConveyorTime';
	import Orders from '../components/Orders';
	import Conveyor from '../components/Сonveyor';
	import Dropout from '../components/Dropout';
	import Mdm from '../components/Mdm';
	import MdmInput from '../components/MdmInput';
	import Lifecycle from '../components/Lifecycle';
	import {mapMutations, mapState} from 'vuex';
	import PriceDevice from '../components/PriceDevice';
	import SelectDevice from '../components/selectDevice';
	import InputCountDevice from '../components/inputCountDevice';
	import TextareaComments from '../components/textareaComments';
	import ConveyorSelect from '../components/ConveyorSelect';
	import Program from '../components/Program';
	const Sticky = require('sticky-js');

	export default {
		data: () => ({
			isStatusSave: false,
			deviceTitle: '',
			dropout: {
				img: 'https://fleep.io/static/assets/2017/fleep_logo_small_blue.svg',
				text: 'Сохранение',
			},
			isServiceLoad: false,
			conveyorsHourTotal: 0,
			mdmInputTotal: 0,
			checkboxTotalPrice: 0,
			programTotalPrice: 0,
			groups: [],
			comments: '',
			calculationName: '',
			countDevice: null,
			programPackage: [
				[
					{
						id: 1,
						name: 'Карта iSurance Gold',
						price: {
							base: 0,
							retail: 0,
							profit: 0
						},
						subText: ''
					},
					{
						id: 2,
						name: 'Гибкая страховая программа',
						price: {
							base: 0,
							retail: 0,
							profit: 0
						},
						subText: ''
					},
					{
						id: 3,
						name: 'Карта Care+',
						price: {
							base: 0,
							retail: 0,
							profit: 0
						},
						subText: 'Для Apple MacPro (Включая все СТО модели) начиная с моделей Late 2019'
					}
				],
			],
			conveyorTime: '',
			finalFilterString: '',
			filterString: '',
			conveyorsSelectString: '',
			filterStringMultiselect: '',
			filterStringConveyor: '',
			filterStringMdm: '',
			filterStringMdmInput: '',
			filterStringConveyorInput: '',
			filterStringLifecycle: '',
			device: '',
			services: [
				{
					order: 1,
					type: 'bool',
					id: 1,
					name: 'Предоставление подменного фонда на время ремонта',
					inputName: '',
					subText: '',
					tooltip: '',
					value: true
				},
				{
					order: 4,
					type: 'multiselect',
					id: 3,
					inputName: '',
					tooltip: '',
					values: [
						{
							id: 1,
							name: 'Персональный Личный кабинет Покупателя',
							price: 'Бесплатно',
							percent: '0,03%',
							percentForWhat: 'Для гибкой СП',
							forWhat: 'Для iSurance'
						}
					]
				},
				{
					order: 5,
					type: 'multiselect',
					id: 4,
					name: 'Где будут обрабатываться заявки',
					inputName: '',
					tooltip: '',
					subText: 'Call - Centr по номеру 8 800 дуступен всем Сервисным программам (СП)',
					values: [
						{
							id: 2,
							name: 'Работа в системе трекинга заявок Заказчика',
							price: '60₽ за устр./мес.',
							percent: '',
							percentForWhat: '',
							forWhat: 'Доступно только в гибкой СП'
						},
					]
				},
			],
			conveyorsSelect: [
				{
					order: 4,
					type: 'select',
					id: 8,
					name: 'Предоставление подменного фонда на время ремонта',
					price: '218',
					inputName: '',
					tooltip: '',
					subText: '',
					selected: false,
					values: [
						{
							index: '0',
							name: 'Предоставление подменного фонда на время ремонта',
							selected: true
						}, {
							index: '1',
							'name': 'Подменный фонд аналогичным устр. (не таким же)',
							selected: false
						}, {
							index: '2',
							'name': 'Предоставление подменного фонда на время ремонта по МСК (до 5 дней срок поставки)',
							selected: false
						}, {
							index: '3',
							'name': 'Предоставление подменного фонда на время ремонта по МСК (день в день)',
							selected: false
						}, {
							index: '4',
							'name': 'Предоставление подменного фонда на время ремонта по России (в теч. 7 дней)',
							selected: false
						}, {
							index: '5',
							'name': 'Предоставление подменного фонда на время ремонта по России (в теч. 2 дней)',
							selected: false
						}
					]
				}
			],
			mdmInput: [
				{
					count: '',
					type: 'input',
					title: 'Тех. обслуживание MDM, 2 000₽/час. ',
					time: '30 ч./мес.',
					rate: 2000,
					price: '60 000₽/мес.',
					description: 'Решение не стандартных задач, доработка модулей решение проблем с тех поддержкой KNOX',
				},
				{
					count: '',
					type: 'input',
					title: 'Обслуживание запросов-заявок, 800₽/час.',
					time: '60 ч./мес.',
					rate: 800,
					price: '48 000₽/мес.',
					description: 'Выполнение задач по запросу заказчика, сброс устр. поиск места нахождения и тд.',
				},
			],
			db: [
				{
					'id': 1,
					'type': 'fix',
					'name': 'Карта Care+ iMac',
					'device': [3],
					'regex': '[0-3]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 10880
						},
						'retail': {
							'type': 'fix',
							'value': 13000
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 2,
					'type': 'fix',
					'name': 'Карта Care+ MacPro',
					'device': [4],
					'regex': '[0-3]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 16192
						},
						'retail': {
							'type': 'fix',
							'value': 19000
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 3,
					'type': 'fix',
					'name': 'Карта Care+ MacMini',
					'device': [5],
					'regex': '[0-3]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 4560
						},
						'retail': {
							'type': 'fix',
							'value': 6000
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 4,
					'type': 'fix',
					'name': 'Карта Care+ Macbook Pro 15/16',
					'device': [6],
					'regex': '[0-3]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 23040
						},
						'retail': {
							'type': 'fix',
							'value': 29000
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 5,
					'type': 'fix',
					'name': 'Карта Care+ Macbook Air/Pro 13',
					'device': [7],
					'regex': '[0-3]010[2-5]000000000011.',
					'price': {
						'base': {
							'type': 'fix',
							'value': 14440
						},
						'retail': {
							'type': 'fix',
							'value': 19000
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 6,
					'type': 'fix',
					'name': 'Карта Care+ iPhone 8/7/6s',
					'device': [8],
					'regex': '[0-2]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 3032
						},
						'retail': {
							'type': 'fix',
							'value': 3990
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 7,
					'type': 'fix',
					'name': 'Карта Care+ iPhone 11/XR/8+/7+/6S+',
					'device': [9],
					'regex': '[0-2]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 3792
						},
						'retail': {
							'type': 'fix',
							'value': 4990
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 8,
					'type': 'fix',
					'name': 'Карта Care+ iPhone 11 Pro/11 Pro Max/XS/XS Max/X',
					'device': [10],
					'regex': '[0-2]0[01]0[02-5]0000000000[01][01].',
					'price': {
						'base': {
							'type': 'fix',
							'value': 6080
						},
						'retail': {
							'type': 'fix',
							'value': 8000
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 9,
					'type': 'fix',
					'name': 'Карта iSurance Silver',
					'device': [],
					'regex': '[10][10]0[04][02-5]00000[01]0000[01][01].',
					'price': {
						'base': {
							'type': 'percent',
							'value': 85
						},
						'retail': {
							'type': 'percent',
							'value': 12
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 10,
					'type': 'fix',
					'name': 'Карта iSurance Gold',
					'device': [],
					'regex': '[20][10][01][04][02-5]00000[01]0000[01][01].',
					'price': {
						'base': {
							'type': 'percent',
							'value': 85
						},
						'retail': {
							'type': 'percent',
							'value': 15
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 11,
					'type': 'fix',
					'name': 'Карта iSurance Platinum',
					'device': [],
					'regex': '[120][120][012][04][02-5]00000[01]0000[01][01].',
					'price': {
						'base': {
							'type': 'percent',
							'value': 85
						},
						'retail': {
							'type': 'percent',
							'value': 22
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [],
					'conveyor': [
						//подменный фонд
						{
							'id': 5,
							'values': {
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
					],
					'mdm': [],
					'lifeCycleDevice': [
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}
						}, {
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}
						}
					]
				}, {
					'id': 3,
					'type': 'dinamyc',
					'name': 'Гибкая страховая программа',
					'device': [],
					'regex': '.*',
					'price': {
						'base': {
							'type': 'percent',
							'value': 85
						},
						'profit': {
							'type': 'diff'
						}
					},
					'services': [
						//гарантийное
						{
							'id': 1,
							'values': {
								'1': {
									'price': {
										'type': 'percent',
										'value': 1
									}
								},
								'2': {
									'price': {
										'type': 'percent',
										'value': 2
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 5
									}
								}
							}
						},
						//не гарантийное
						{
							'id': 2,
							'values': {
								'1': {
									'price': {
										'type': 'percent',
										'value': 7
									}
								},
								'2': {
									'price': {
										'type': 'percent',
										'value': 12
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 19
									}
								}
							}
						},
						//кол-во инциндентов
						{
							'id': 4,
							'values': {
								'1': {
									'price': {
										'type': 'percent',
										'value': 0
									}
								},
								'2': {
									'price': {
										'type': 'percent',
										'value': 3
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 5
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 6
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 8
									}
								},
								'6': {
									'price': {
										'type': 'percent',
										'value': 10
									}
								}
							}
						},
						//логистика
						{
							'id': 3,
							'values': {
								'1': {
									'price': {
										'type': 'percent',
										'value': 0.5
									}
								},
								'2': {
									'price': {
										'type': 'percent',
										'value': 2.5
									}
								}
							}
						}
					],
					'conveyor': [
						//базовый конвейер
						{
							'id': 6,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 480
									}
								}
							}
						},
						//Первичная настройка
						{
							'id': 7,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 216
									}
								}
							}
						},
						//устройство со склада
						{
							'id': 8,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 144
									}
								}
							}
						},
						//персональный ЛК
						{
							'id': 11,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 0
									}
								}
							}
						},
						//система заказчика
						{
							'id': 10,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 60
									}
								}
							}
						},
						//подменный фонд
						{
							'id': 5,
							'values': {
								'1': {
									'price': {
										'type': 'percent',
										'value': 2
									}
								},
								'2': {
									'price': {
										'type': 'percent',
										'value': 21
									}
								},
								'3': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'4': {
									'price': {
										'type': 'percent',
										'value': 24
									}
								},
								'5': {
									'price': {
										'type': 'percent',
										'value': 30
									}
								}
							}
						},
						//инструцкции
						{
							'id': 14,
							'values': {
								'1': {
									'price': {
										'type': 'multiply',
										'value': 3000
									}
								}
							}
						},
						//обучение
						{
							'id': 15,
							'values': {
								'1': {
									'price': {
										'type': 'multiply',
										'value': 4000
									}
								}
							}
						}
					],
					'mdm': [
						//лицензии Knox Manage
						{
							'id': 9,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 3000
									}
								}
							}
						},
						//Тех обслуживание
						{
							'id': 12,
							'values': {
								'1': {
									'price': {
										'type': 'multiply',
										'value': 2000
									}
								}
							}
						},
						//Заявки
						{
							'id': 13,
							'values': {
								'1': {
									'price': {
										'type': 'multiply',
										'value': 800
									}
								}
							}
						}
					],
					'lifeCycleDevice': [
						//Удаление данных
						{
							'id': 17,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 800
									}
								}
							}

						},
						//Утилизация
						{
							'id': 16,
							'values': {
								'1': {
									'price': {
										'type': 'fix',
										'value': 250
									}
								}
							}

						}
					]
				}
			],
		}),
		components: {
			Program,
			ConveyorSelect,
			TextareaComments,
			InputCountDevice,
			SelectDevice,
			Select,
			ConveyorTime,
			Orders,
			Conveyor,
			Dropout,
			Mdm,
			MdmInput,
			Lifecycle,
			PriceDevice
		},
		methods: {
			...mapMutations(['changeCalculationInfo']),

			setStatusSave() {
				this.isStatusSave = true;

				setTimeout(() => {
					this.isStatusSave = false;
				}, 1000);
			},
			setStatusService(value) {
				this.isServiceLoad = value;
			},

			getAllPrice(p, data, cost) {
				let price = {
					base: 0,
					retail: cost,
					profit: 0
				};

				if (p.price.base.type === 'fix') {
					price.base += p.price.base.value * data.count;
				} else if (p.price.base.type === 'percent') {
					price.base += (price.retail / 100) * p.price.base.value;
				}

				if (p.price.profit.type === 'fix') {
					price.profit += p.price.profit.value * data.count;
				} else if (p.price.profit.type === 'diff') {
					price.profit += price.retail - price.base;
				}
				return price;
			},
			strToId(s) {
				let i = 1;
				let ids = [];

				for (let c of s) {
					if (c !== '0') {
						ids.push(i);
					}
					i++;
				}
				return ids;
			},
			getPriceService(p, data) {
				var cost = 0;
				if (p.price.retail.type === 'fix') {
					cost += p.price.retail.value * data.count;
				} else if (p.price.retail.type === 'percent') {
					cost += ((data.count * data.price) / 100) * p.price.retail.value;
				}

				// if (p.price.base.type=="fix"){
				// 	cost.base +=  p.price.base.value * data.count
				// }else if (p.price.base.type=="percent") {
				// 	cost.base +=  (cost.retail / 100) * p.price.base.value
				// }

				// if (p.price.profit.type=="fix"){
				// 	cost.base +=  p.price.profit.value * data.count
				// }else if (p.price.profit.type=="diff") {
				// 	cost.profit +=  cost.retail - cost.base
				// }

				return cost;
			},
			calcPriceGroup(group, data) {
				let price;
				let cost = 0;
				let ids = this.strToId(data.values);

				for (let s of group) {
					if (ids.includes(s.id)) {
						if (s.values[data.values[s.id - 1]]) {
							price = s.values[data.values[s.id - 1]].price;
						}
						// let price = s.values[data.values[s.id - 1]].price;
						if (price) {
							// console.log('price.type', price.type);
							if (price.type === 'fix') {
								cost += price.value;
							} else if (price.type === 'percent') {
								cost += ((data.count * data.price) / 100) * price.value;
							} else if (price.type === 'multiply') {
								// console.log(data.hours[s.id])
								cost += price.value * Number(data.hours[s.id]);
							}
						}

					}
				}
				return cost;
			},
			chooseProgram(payload, data, globalIndex) {
				// console.log(data);
				let availablePackage = [];
				payload.forEach(p => {
					//generate regex
					let reValues = new RegExp(p.regex, 'g');
					//check available
					if (data.values.match(reValues) && ((data.deviceType == '0' || data.deviceType == '') || (p.device.length == 0 || p.device.includes(Number(data.deviceType))))) {
						// console.log(p.regex);
						let cost = 0;
						if (p.type === 'fix') {
							cost += this.getPriceService(p, data);
						} else {
							cost += this.calcPriceGroup(p.services, data);
						}
						// console.log("1: " + cost)
						cost += this.calcPriceGroup(p.conveyor, data);
						// console.log("2: " + cost)
						cost += this.calcPriceGroup(p.mdm, data);
						// console.log("3: " + cost)
						cost += this.calcPriceGroup(p.lifeCycleDevice, data);
						// console.log("4: " + cost)
						let price = this.getAllPrice(p, data, cost);
						availablePackage.push({
							id: p.id,
							name: p.name,
							price,
							subText: p.device[0] ? p.device[0] : ''
						});
					}
				});
				// this.groups[globalIndex].programPackage = availablePackage;
				this.saveArea(availablePackage, globalIndex, 'programPackage');
			},

			openGroup(event) {
				if (event.target.closest('.devices__item').classList.contains('devices__item--active')) {
					event.target.closest('.devices__item').classList.remove('devices__item--active');
				} else {
					event.target.closest('.devices__item').classList.add('devices__item--active');
				}
			},
			deleteGroup(id) {
				this.groups = this.groups.filter((group, index) => index !== id);

				this.saveCalculate();
			},
			addNewGroup() {
				this.groups.push({
					name: 'Группа устройств',
					device: '',
					deviceTitle: '',
					comments: '',
					filterString: '',
					filterStringMultiselect: '',
					conveyorsBooleanExtraPrice: 0,
					mdmBooleanExtraPrice: 0,
					lifeCycleBooleanExtraPrice: 0,
					conveyorsSelectString: '',
					filterStringConveyor: '',
					filterStringMdm: '',
					filterStringMdmInput: '',
					filterStringConveyorInput: '',
					filterStringLifecycle: '',
					finalFilterString: '',
					countDevice: null,
					priceDevice: null,
					id: null,
					mdmInput: [
						{
							count: '',
							type: 'input',
							title: 'Тех. обслуживание MDM, 2 000₽/час. ',
							time: '30 ч./мес.',
							rate: 2000,
							price: '60 000₽/мес.',
							description: 'Решение не стандартных задач, доработка модулей решение проблем с тех поддержкой KNOX',
						},
						{
							count: '',
							type: 'input',
							title: 'Обслуживание запросов-заявок, 800₽/час.',
							time: '60 ч./мес.',
							rate: 800,
							price: '48 000₽/мес.',
							description: 'Выполнение задач по запросу заказчика, сброс устр. поиск места нахождения и тд.',
						},
					],
					conveyorsHour: [
						{
							count: '',
							order: 1,
							type: 'hour',
							rate: 3000,
							name: 'Составление инструкций, 3000 ₽/час.',
							inputName: '',
							tooltip: '',
							subText: 'Составление инструкции по настроке устройств по эталонному образцу'
						},
						{
							count: '',
							order: 7,
							type: 'hour',
							rate: 4000,
							name: 'Проведение обучения, 4 000₽/час. ',
							inputName: '',
							tooltip: '',
							subText: 'Проведение обучения и подготовка обучающих  материалов'
						},
					],
					servicesSelect: [
						{
							order: 2,
							type: 'select',
							id: 2,
							name: 'Срок гарантийного обслуживания',
							inputName: '',
							subText: 'Количество гарантийных инцидентов в течение срока гарантийного обслуживания не огранично',
							tooltip: '',
							values: [
								{
									index: '0',
									name: 'Срок гарантийного обслуживания',
									selected: true
								},
								{
									index: '1',
									name: '1 год',
									selected: false
								}, {
									index: '2',
									name: '2 года',
									selected: false
								}, {
									index: '3',
									name: '3 года',
									selected: false
								}
							]
						},
						{
							order: 3,
							type: 'select',
							id: 3,
							name: 'Срок не гарантийного обслуживания',
							inputName: '',
							subText: 'Количество гарантийных инцидентов в течение срока гарантийного обслуживания не огранично',
							tooltip: '',
							title: 'Срок не гарантийного обслуживания',
							values: [
								{
									index: '0',
									name: 'Срок не гарантийного обслуживания',
									selected: true
								},
								{
									index: '1',
									name: '1 год',
									selected: false
								}, {
									index: '2',
									name: '2 года',
									selected: false
								}, {
									index: '3',
									name: '3 года',
									selected: false
								}
							]
						},
						{
							type: 'select',
							id: 4,
							name: 'Логистика',
							inputName: '',
							subText: 'Забор устройства и обратная доставка столько раз сколько гарантийных и не гарантийных ремонтов и замен предусмотрено выбранными опциями',
							tooltip: '',
							title: 'Логистика',
							values: [
								{
									index: '0',
									name: 'Логистика',
									selected: true
								},
								{
									index: '1',
									name: 'Логистика по Москве',
									selected: false
								}, {
									index: '2',
									name: 'Логистика по России',
									selected: false
								}
							]
						},
						{
							'type': 'select',
							'id': 3,
							'name': 'Кол-во не гарантийных инцидентов в течение срока обслуживания',
							'inputName': '',
							'subText': 'Количество гарантийных инцидентов в течение срока гарантийного обслуживания не огранично. Устройство в рамках программы или заменяется и больше не ремонтируется, или ремонтируется, но в случае последней замены стоимость предыдущего ремонта возвращается обратно.',
							'tooltip': '',
							'title': 'Кол-во не гарантийных инцидентов в течение срока обслуживания',
							'values': [
								{
									index: '0',
									name: 'Кол-во не гарантийных инцидентов в течение срока обслуживания',
									selected: true
								}, {
									index: '1',
									'name': '1 ремонт',
									selected: false
								}, {
									index: '2',
									'name': '2 ремонта',
									selected: false
								}, {
									index: '3',
									'name': '3 ремонта',
									selected: false
								}, {
									index: '4',
									'name': '1 ремонт / 1 замена (замена закрывает договор)',
									selected: false
								}, {
									index: '5',
									'name': '2 ремонта / 1 замена / 1 ремонта + 1 замена (замена закрывает договор)',
									selected: false
								}, {
									index: '6',
									'name': '3 ремонта / 1 замена / 2 ремонта + 1 замена (замена закрывает договор)',
									selected: false
								}
							]
						}
					],
					programPackage: [],
					selectedProgram: null,
					selectedProgramPrice: null,
					extraMoney: null,
					lifeCycleDevice: [
						{
							title: 'Утилизация',
							price: '250'
						}, {
							title: 'Удаление данных устройств',
							price: '800'
						}, {
							title: 'Обратный выкуп, Trade-in 1 год',
							price: '480',
							buyout: true,
						},
					],
					devices: [
						{
							index: '0',
							name: 'Выберете тип устройства',
							selected: true
						},
						{
							selected: false,
							name: 'Планшет',
							index: '1',
						},
						{
							selected: false,
							name: 'Смартфон',
							index: '2',
						}, {
							selected: false,
							name: 'Apple IMac (Включая все СТО модели) начиная с моделей Late 2019',
							index: '3',
						}, {
							selected: false,
							name: 'Apple MacPro (Включая все СТО модели) начиная с моделей Late 2019',
							index: '4',
						}, {
							selected: false,
							name: 'Apple MacMini (Включая все СТО модели) начиная с моделей Late 2019',
							index: '5',
						}, {
							selected: false,
							name: 'Apple MacBook Pro 15’’|16’’ (Включая все СТО модели) начиная с моделей Late 2019',
							index: '6',
						}, {
							selected: false,
							name: 'Apple Apple MacBook Air | MacBook Pro 13’’ (Включая все СТО модели) начиная с моделей Late 2019',
							index: '7',
						}, {
							selected: false,
							name: 'Apple iPhone 8, iPhone 7 и iPhone 6s',
							index: '8',
						}, {
							selected: false,
							name: 'Apple iPhone 11, iPhone XR, iPhone 8 Plus, iPhone 7 Plus и iPhone 6s Plus',
							index: '9',
						}, {
							selected: false,
							name: 'Apple iPhone 11 Pro, iPhone 11 Pro Max, iPhone XS, iPhone XS Max, and iPhone X',
							index: '10',
						}
					],
					servicesMultiselect: {
						order: 5,
						type: 'multiselect',
						id: 4,
						name: 'Где будут обрабатываться заявки',
						inputName: '',
						tooltip: '',
						subText: 'Call - Centr по номеру 8 800 дуступен всем Сервисным программам (СП)',
						values: [
							{
								name: 'Работа в системе трекинга заявок Заказчика',
								price: '60₽ за устр./мес.',
								percent: '',
								percentForWhat: '',
								forWhat: 'Доступно только в гибкой СП',
								selected: false,
							},
							{
								name: 'Персональный Личный кабинет Покупателя',
								price: 'Бесплатно',
								percent: '0,03%',
								percentForWhat: 'Для гибкой СП',
								forWhat: 'Для iSurance',
								selected: false,
							}
						]
					},
					conveyorsBoolean: [
						{
							order: 2,
							type: 'bool',
							id: 6,
							name: 'Базовый конвейер. Новые устройства',
							price: '480',
							inputName: '',
							tooltip: '',
							subText: '',
							selected: false,
						},
						{
							order: 1,
							type: 'bool',
							id: 5,
							name: 'Первичная загрузка приложений на устройство по списку от заказчика.',
							price: '216',
							inputName: '',
							tooltip: '',
							subText: '',
							selected: false,
						}, {
							order: 3,
							type: 'bool',
							id: 7,
							name: 'Устройства со склада Заказчика, возможно Б/У',
							price: '144',
							inputName: '',
							tooltip: '',
							subText: '',
							selected: false,
						},
					],
					mdmCheckbox: [
						{
							type: 'checkbox',
							title: 'Лицензии Knox Manage',
							price: '3240',
							selected: false
						},
					],
				});
				this.saveCalculate();
			},

			setCountSymbolArea(globalIndex, countElement, area) {
				if (this.$route.query.calculationId === undefined && this.groups[globalIndex][area].length === 0) {
					if (countElement !== this.groups[globalIndex][area].length) {
						for (let i = 0; i < countElement; i++) {
							this.groups[globalIndex][area] += '0';
						}
					}
					// console.log('Сейчас мы формируем строку', area, 'Нулей должно быть: ', countElement, 'в итоге мы задали ей значение', this.groups[globalIndex][area]);
					if (area === 'filterStringMdmInput' || area === 'filterStringConveyorInput') {
						let input = area === 'filterStringMdmInput' ? 'mdmInput' : 'conveyorsHour';
						let changeArea = area === 'filterStringMdmInput' ? 'filterStringMdmInput' : 'filterStringConveyorInput';
						this.formStringForInputs(globalIndex, area, input, changeArea);
					}

					this.saveArea(this.groups[globalIndex][area], globalIndex, area);
					this.saveData(globalIndex);
				}

				this.setExtraMoney(globalIndex);
			},

			formStringForInputs(globalIndex, area, input) {
				this.groups[globalIndex][input].forEach((input, index) => {
					if (input.count.length === 0) {
						this.groups[globalIndex][area] = this.groups[globalIndex][area].replaceAt(index, '0');
					} else {
						this.groups[globalIndex][area] = this.groups[globalIndex][area].replaceAt(index, '1');
					}
				});

				this.saveArea(this.groups[globalIndex][area], globalIndex, area);
				this.saveData(globalIndex);
			},

			//тут в data приходит выбранный элемент
			//в number приходит с какого по счету select пришла инфа
			filterProgram(number, data, isSelect, globalIndex) {
				this.groups[globalIndex].servicesSelect.forEach((service, index) => {
					if (index + 1 === Number(number)) {
						service.values.forEach((item, index) => {
							item.selected = index + 1 === Number(data + 1);
						});
					}
				});
				document.querySelectorAll('.filter-item').forEach((service, index) => {
					if (index + 1 === number) {
						if (isSelect) {
							this.groups[globalIndex].filterString = this.groups[globalIndex].filterString.replaceAt(index, data.toString());
						} else {
							this.groups[globalIndex].filterString = this.groups[globalIndex].filterString.replaceAt(index, '1');
						}

						if (data && data.countOpen === 0) {
							this.groups[globalIndex].filterString = this.groups[globalIndex].filterString.replaceAt(index, '0');
						}
					}
				});

				this.saveArea(this.groups[globalIndex].filterString, globalIndex, 'filterString');
				this.saveData(globalIndex);
				this.resetProgram(globalIndex);
			},

			//тут в data приходит выбранный элемент
			//в number приходит с какого по счету select пришла инфа
			filterProgramMultiselect(number, data, globalIndex) {
				this.groups[globalIndex].servicesMultiselect.values.forEach((service, index) => {
					if (index + 1 === number) {
						service.selected = !service.selected;

						if (service.selected) {
							this.groups[globalIndex].filterStringMultiselect = this.groups[globalIndex].filterStringMultiselect.replaceAt(number - 1, '1');
						} else {
							this.groups[globalIndex].filterStringMultiselect = this.groups[globalIndex].filterStringMultiselect.replaceAt(number - 1, '0');
						}
					}
				});
				this.saveArea(this.groups[globalIndex].filterStringMultiselect, globalIndex, 'filterStringMultiselect');
				this.saveData(globalIndex);
				this.resetProgram(globalIndex);
			},

			filterProgramCheckbox(number, data, globalIndex, className, area) {
				document.querySelectorAll(`.${className}`).forEach((service, index) => {
					if (index + 1 === number) {
						if (data.selected) {
							this.groups[globalIndex][area] = this.groups[globalIndex][area].replaceAt(index, '1');
						} else {
							this.groups[globalIndex][area] = this.groups[globalIndex][area].replaceAt(index, '0');
						}
					}
				});
				this.saveArea(this.groups[globalIndex][area], globalIndex, area);
				this.saveData(globalIndex);
				this.setExtraMoney();
				this.resetProgram(globalIndex);
			},

			//в number приходит с какого инпута идет инфа
			enterValueArea(value, number, globalIndex, area) {
				this.groups[globalIndex][area].forEach((item, index) => {
					if (index === number) {
						item.count = value;
					}
				});
				this.saveArea(this.groups[globalIndex][area], globalIndex, area);
				this.saveData(globalIndex);

				if (area === 'conveyorsHour') {
					this.formStringForInputs(globalIndex, 'filterStringConveyorInput', 'conveyorsHour');
					this.setTotalPriceInputs('conveyorsHour');
				} else if (area === 'mdmInput') {
					this.formStringForInputs(globalIndex, 'filterStringMdmInput', 'mdmInput');
					this.setTotalPriceInputs('mdmInput');
				}

				this.resetProgram(globalIndex);
			},

			formatFilterString(globalIndex) {
				this.groups[globalIndex].finalFilterString =
				this.groups[globalIndex].filterString +
				this.groups[globalIndex].conveyorsSelectString +
				this.groups[globalIndex].filterStringConveyor +
				this.groups[globalIndex].filterStringMdm +
				this.groups[globalIndex].filterStringMultiselect +
				this.groups[globalIndex].filterStringMdmInput +
				this.groups[globalIndex].filterStringConveyorInput +
				this.groups[globalIndex].filterStringLifecycle;
				this.chooseProgram(this.db, {
					values: this.groups[globalIndex].finalFilterString,
					hours: {
						'11': this.groups[globalIndex].conveyorsHour[0].count,
						'12': this.groups[globalIndex].conveyorsHour[1].count,
						'14': this.groups[globalIndex].mdmInput[0].count,
						'15': this.groups[globalIndex].mdmInput[1].count,
					},
					deviceType: this.groups[globalIndex].device,
					count: this.groups[globalIndex].countDevice,
					price: this.groups[globalIndex].priceDevice
				}, globalIndex);
			},

			setTitleDevice(title, globalIndex, area) {
				this.deviceTitle = title;
				this.saveArea(title, globalIndex, area);
				this.saveData(globalIndex);
			},

			updateArea(price, globalIndex, area) {
				if (area === 'device') {
					this.device = price;
				}
				this.saveArea(price, globalIndex, area);
				this.saveData(globalIndex);

				if (area === 'priceDevice') {
					this.setBuyoutLifecycle(globalIndex, price);
					this.setTotalPriceDevice();
				}

				this.resetProgram(globalIndex);
			},

			saveArea(value, index, area) {
				this.groups[index][area] = value;
			},

			saveData(indexGroup) {
				this.setStatusSave();
				this.saveCalculate();
				this.formatFilterString(indexGroup);
			},

			saveCalculate() {
				if (this.$route.query.calculationId !== undefined) {
					this.$store.commit('setIdCalculation', this.$route.query.calculationId);
				}

				this.$store.commit('changeCalculationInfo', this.groups);
				this.$store.dispatch('SAVE_CALCULATION', this.totalPriceValue());
			},

			createDublicate() {
				this.$store.commit('setCurrentPageId', this.idNewCalculation);
				localStorage.setItem(`temporaryStorage-${this.idNewCalculation}`, JSON.stringify(this.groups));
				this.$store.dispatch('CREATE_DUPLICATE_CALCULATION', {
					name: this.calculationName,
					cost: 0,
					numberIsMainPage: this.idNewCalculation
				});
			},

			createNewCalculate() {
				this.$store.dispatch('CREATE_CALCULATION')
				.then(() => this.$router.go(0));
			},

			setTitle() {
				//проверяем, что если в строке есть заголовок дубликата, ставим его
				if (this.$route.query.duplicateTitle && this.$route.query.duplicateTitle.length) {
					this.calculationName = `Копия-${this.$route.query.duplicateTitle}`;
				} else {
					//если это не страница дубликата, тогда смотрим,
					//что если title еще не установлен, то генерируем новый
					if (!this.calculationTitle) {
						this.calculationName = `Новый рассчет-${this.$route.query.pageId}`;
					} else {
						//если title уже есть, просто ставим его
						this.calculationName = this.calculationTitle;
					}
				}

				this.$store.commit('setTitleCalculation', this.calculationName);
			},
			setDataCalculation() {
				this.groups = this.calculations;
				this.setTitle();
				this.saveCalculate();
			},
			saveTitle() {
				this.$store.commit('setTitleCalculation', this.calculationName);
				this.saveData(0);
			},

			setTotalPriceInputs(area) {
				let totalPriceInputs = [];
				let totalResultInputs;
				this.groups.forEach(group => {
					group[area].forEach(item => {
						if (item.count.length > 0) {
							totalPriceInputs.push(item.rate * item.count);
						}
					});
				});

				if (totalPriceInputs.length) {
					totalResultInputs = totalPriceInputs.reduce((accumulator, currentValue) => accumulator + currentValue);
					this[`${area}Total`] = totalResultInputs;
				}
			},

			setBuyoutLifecycle(globalIndex, price) {
				this.groups[globalIndex].lifeCycleDevice.forEach(item => {
					if (item.buyout) {
						item.price = Math.floor(+price / 3);
					}
				});
			},

			setTotalProgram() {
				let totalProgramArray = [];
				let resultTotalProgram;
				this.groups.forEach(group => {
					totalProgramArray.push(group.selectedProgramPrice);
				});

				if (totalProgramArray.length) {
					resultTotalProgram = totalProgramArray.reduce((accumulator, currentValue) => accumulator + currentValue);
				}

				this.programTotalPrice = resultTotalProgram;
			},

			setExtraMoney() {
				let extraMoney = [];
				let totalResultExtraMoney;
				this.groups.forEach(group => {
					group.lifeCycleBooleanExtraPrice = null;
					group.lifeCycleDevice.forEach(item => {
						if (item.selected) {
							item.price = item.price.toString().replace(/\s/g, '');
							// записываем сколько из этого конвеера, для рассчета процентов
							group.lifeCycleBooleanExtraPrice += +item.price;
							extraMoney.push(+item.price);
						}
					});
				});

				this.groups.forEach(group => {
					group.mdmBooleanExtraPrice = null;
					group.mdmCheckbox.forEach(item => {
						if (item.selected) {
							item.price = item.price.toString().replace(/\s/g, '');
							// записываем сколько из этого конвеера, для рассчета процентов
							group.mdmBooleanExtraPrice += +item.price;
							extraMoney.push(+item.price);
						}
					});
				});

				this.groups.forEach(group => {
					group.conveyorsBooleanExtraPrice = null;
					group.conveyorsBoolean.forEach(item => {
						if (item.selected) {
							item.price = item.price.toString().replace(/\s/g, '');
							// записываем сколько из этого конвеера, для рассчета процентов
							group.conveyorsBooleanExtraPrice += +item.price;
							extraMoney.push(+item.price);
						}
					});
				});

				if (extraMoney.length) {
					totalResultExtraMoney = extraMoney.reduce((accumulator, currentValue) => accumulator + currentValue);
					this.checkboxTotalPrice = totalResultExtraMoney;
				} else {
					this.checkboxTotalPrice = 0;
				}
			},

			setTotalPriceDevice() {
				let priceDeviceArray = [];
				let totalDevicePrice;
				this.groups.forEach(group => {
					priceDeviceArray.push(+group.priceDevice);
				});

				if (priceDeviceArray.length) {
					totalDevicePrice = priceDeviceArray.reduce((accumulator, currentValue) => accumulator + currentValue);
				}

				this.setExtraMoney();
			},
			resetProgram(globalIndex) {
				this.groups[globalIndex].selectedProgram = null;
				this.groups[globalIndex].selectedProgramPrice = null;

				this.setTotalProgram();
			},
			setProgram(value, globalIndex) {
				// this.groups[globalIndex].selectedProgram = value.indexProgram;
				// this.groups[globalIndex].selectedProgramPrice = value.price;
				this.saveArea(value.indexProgram, globalIndex, 'selectedProgram');
				this.saveArea(Math.floor(value.price), globalIndex, 'selectedProgramPrice');
				this.setTotalProgram();
				this.totalPriceValue(this.groups[globalIndex].selectedProgramPrice);
				this.saveData(globalIndex);
				this.setStatusSave();
			},
			totalPriceValue(valueProgramPrice) {
				if (valueProgramPrice) {
					return this.checkboxTotalPrice + this.conveyorsHourTotal + this.mdmInputTotal + this.programTotalPrice + valueProgramPrice;
				} else {
					return this.checkboxTotalPrice + this.conveyorsHourTotal + this.mdmInputTotal + this.programTotalPrice;
				}
			}
		},
		computed: {
			...mapState([
				'groupsState',
				'calculations',
				'idNewCalculation',
				'calculationTitle',
				'currentPageId',
			]),
		},

		mounted() {

			let infoArray = [];
			//Ставим id для сохранений
			if (this.$route.query.pageId !== undefined) {
				this.$store.commit('setIdCalculation', this.$route.query.pageId);
				if (localStorage.getItem(`temporaryStorage-${this.$route.query.numberIsMainPage}`)) {
					infoArray = JSON.parse(localStorage.getItem(`temporaryStorage-${this.$route.query.numberIsMainPage}`));
				}
			} else {
				this.$store.commit('setIdCalculation', this.$route.query.calculationId);
			}

			//Смотрим, есть ли данные для данной страницы
			this.$store.dispatch('GET_CALCULATION', this.idNewCalculation)
			.then(() => {
				if (this.$route.query.calculationId !== undefined) {
					this.setDataCalculation();
				} else {
					if (Object.keys(this.calculations).length === 0 && this.$route.query.pageId !== undefined) {
						if (infoArray.length) {
							this.$store.commit('changeCalculationInfo', infoArray);
							this.setTitle();
							this.groups = infoArray;
						} else {
							console.log('сделали новую страницу');
							this.groups = this.groupsState;
							this.setTitle();
						}
						this.$store.commit('setTitleCalculation', this.calculationName);
					} else {
						console.log('загрузили данные из готвоой');
						this.setDataCalculation();
					}
				}

				this.saveCalculate();
			}).then(() => {
				this.setTotalPriceDevice();
				this.setTotalPriceInputs('conveyorsHour');
				this.setTotalPriceInputs('mdmInput');
				this.setExtraMoney();
				this.setTotalProgram();

				this.$store.dispatch('SAVE_CALCULATION', this.totalPriceValue());
			});

			String.prototype.replaceAt = function (index, replacement) {
				return this.substr(0, index) + replacement + this.substr(index + replacement.length);
			};
		}
	};
</script>
