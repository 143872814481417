<template>
	<div class="small-checkbox" @click.prevent="selectMdm(mdm)">
		<span class="small-checkbox__group">
			<input type="checkbox" v-model="checked" class="small-checkbox__input" name="licenses">
			<span class="small-checkbox__icon"></span>
			<span class="small-checkbox__name">{{mdm.title}}</span>
		</span>
		<span class="small-checkbox__price">{{mdm.price}}₽</span>
	</div>
</template>

<script>
	export default {
		name: 'Mdm',
		props: ['mdm', 'selectedInfo'],
		data: () => ({
			checked: false
		}),
		methods: {
			selectMdm(value) {
				this.checked = !this.checked;
				value.selected = this.checked;
				this.$emit('selectMdm', value);
			},
		},
		mounted() {
			this.checked = +this.selectedInfo;
			this.$emit('isLoadInfo', true)
		}
	};
</script>

<style scoped>

</style>
