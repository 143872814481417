<template>
	<div class="devices__input-group">
		<input type="text" class="devices__input" :id="`notes-${globalIndex}`"
			   @input="updateArea"
			   v-model="comments"
			   placeholder="Ваши заметки и комментарии">
		<label :for="`notes-${globalIndex}`" class="devices__label">Ваши заметки и комментарии</label>
	</div>
</template>

<script>
	export default {
		name: 'textareaComments',
		props: ['valuePrice', 'globalIndex'],
		data: () => ({
			comments: ''
		}),
		methods: {
			updateArea() {
				this.$emit('updateArea', this.comments)
			}
		},
		mounted() {
			this.comments = this.valuePrice;
		}
	};
</script>

<style scoped>

</style>
