<template>
	<label class="small-checkbox" @click.prevent="selectLifecycle(device)" :for="`device-life-${indexLifeCycle}`">
		<span class="small-checkbox__group">
			<input type="checkbox" class="small-checkbox__input" name="device-life" :id="`device-life-${indexLifeCycle}`" v-model="checked">
			<span class="small-checkbox__icon"></span>
			<span class="small-checkbox__name">{{device.title}}</span>
		</span>
		<span class="small-checkbox__price" v-if="!device.buyout">{{device.price}}₽</span>
		<span class="small-checkbox__price" v-else>{{Math.floor(+priceDevice / 3)}}₽</span>
	</label>
</template>

<script>
	export default {
		name: 'Lifecycle',
		props:['device', 'selectedInfo', 'indexLifeCycle', 'priceDevice'],
		data: () => ({
			checked: false,
		}),
		methods: {
			selectLifecycle(value) {
				this.checked = !this.checked;
				value.selected = this.checked;
				this.$emit('selectLifecycle', value);
			},
		},
		mounted() {
			this.checked = +this.selectedInfo;
			this.$emit('isLoadInfo', true)
		}
	};
</script>

<style scoped>

</style>
