<template>
	<div class="dropout_container">
		<img class="dropout_img" src="../assets/img/dist/check-save.svg" alt=""/>
		<div class="dropout_text">Сохранено</div>
	</div>
</template>
<script>
	export default {
		name: 'Dropout',
		data: () => ({
		}),
	};
</script>
