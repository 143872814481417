<template>
	<div>
		<input v-model="checked" type="checkbox" class="custom-checkbox__input" :id="`product-${indexServicesMultiselect}`">
		<label :for="`product-${indexServicesMultiselect}`" class="custom-checkbox__label" @click="selectOrder(service)">
			<span class="custom-checkbox__title">{{service.name}}</span>
			<span class="custom-checkbox__inner">
				<span class="custom-checkbox__block">
					<span class="custom-checkbox__name">{{service.price}}</span>
					<span class="custom-checkbox__help">{{service.forWhat}}</span>
				</span>
				<span class="custom-checkbox__block" v-if="service.percent">
					<span class="custom-checkbox__name">{{service.percent}}</span>
					<span class="custom-checkbox__help">{{service.percentForWhat}}</span>
				</span>
			</span>
		</label>
	</div>
</template>

<script>
	export default {
		name: 'Orders',
		props: ['service', 'selectedInfo', 'indexServicesMultiselect'],
		data: () => ({
			checked: false,
			countOpen: 0,
		}),
		methods: {
			selectOrder(value) {
				this.$emit('selectOrder', value);
			},
		},
		mounted() {
			this.checked = +this.selectedInfo;
			this.$emit('setStatusService', true);
		}
	};
</script>

<style scoped>

</style>
