<template>
	<div>
		<input type="number"
			   class="devices__input devices__input--help"
			   v-model="countDevice"
			   @input="updateArea"
			   :id="`quantity-${globalIndex}`">
		<label :for="`quantity-${globalIndex}`" class="devices__label">Кол-во</label>
		<span class="devices__help">шт.</span>
	</div>
</template>

<script>
	export default {
		name: 'inputCountDevice',
		props: ['valuePrice', 'globalIndex'],
		data: () => ({
			countDevice: ''
		}),
		methods: {
			updateArea() {
				this.$emit('updateArea', this.countDevice)
			}
		},
		mounted() {
			if(this.valuePrice !== null) {
				this.countDevice = this.valuePrice;
			}
		}
	};
</script>

<style scoped>

</style>
