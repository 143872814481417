<template>
	<div class="devices__input-group">
		<input type="text" class="devices__input filter-item-input-mdm devices__input--help"
			   :id="`licenses-input-${index}`"
			   v-model="mdm"
			   @input="enterMdm(item)">
		<label :for="`licenses-input-${index}`" class="devices__label">{{item.title}}</label>
		<span class="devices__help">{{item.rate * mdm}} ₽</span>
		<p class="devices__paragraph">{{item.description}}</p>
	</div>
</template>

<script>
	export default {
		name: 'MdmInput',
		props: ['item', 'index'],
		data: () => ({
			mdm: '',
		}),
		methods: {
			enterMdm(value) {
				if(this.mdm < 1) {
					this.mdm = 0;
				}
				value.count = this.rate;
				this.$emit('enterMdm', this.mdm)
			}
		},
		mounted() {
			// console.log('MdmInput item', this.item);
			this.mdm = this.item.count;
			this.$emit('isLoadInfo', true)
		}

	};
</script>

<style scoped>

</style>
