<template>
	<label>
		<input type="number"
			   class="devices__input devices__input--help filter-item-conveyor-time"
			   v-model="rate"
			   :id="`conveyor-input-${index}`"
			   @input="enterConveyor(conveyor)"
		>
		<label :for="`conveyor-input-${index}`" class="devices__label">{{conveyor.name}}</label>
		<span class="devices__help">{{rate * conveyor.rate}} ₽</span>
		<p class="devices__paragraph">{{conveyor.subText}}</p>
	</label>
</template>

<script>
	export default {
		props: ['conveyor', 'index'],
		data: () => ({
			rate: ''
		}),
		methods: {
			enterConveyor(value) {
				if (this.rate < 1) {
					this.rate = 0;
				}

				value.count = this.rate;
				this.$emit('enterConveyor', this.rate);
			}
		},
		mounted() {
			this.rate = this.conveyor.count;
			this.$emit('isLoadInfo', true)
		}
	};
</script>
