<template>
	<div class="small-checkbox" @click.prevent="selectConveyor($event, conveyor)">
		<div class="small-checkbox__group">
			<input type="checkbox" v-model="checkedConveyor" class="small-checkbox__input"
				   name="conveyor">
			<span class="small-checkbox__icon"></span>
			<span class="small-checkbox__name">{{conveyor.name}}</span>
		</div>
		<span class="small-checkbox__price">{{conveyor.price}}₽</span>
	</div>
</template>

<script>
	export default {
		name: 'Сonveyor',
		props: ['conveyor', 'selectedInfo'],
		data: () => ({
			countOpen: 0,
			checkedConveyor: false
		}),
		methods: {
			selectConveyor(event, value) {
				this.checkedConveyor = !this.checkedConveyor;
				value.selected = this.checkedConveyor;
				this.$emit('selectConveyor', value);
			},
		},
		mounted() {
			this.checkedConveyor = +this.selectedInfo;
			this.$emit('isLoadInfo', true);
		}
	};
</script>

<style scoped>

</style>
